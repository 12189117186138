
@import "~lessRoot/_mixins";
@import "~lessRoot/_variables";

.dxp-card-widget-card {
	display: flex;
	padding: 15px 0 15px 15px;
	border: 1px solid #CBD0E4;
	border-radius: 6px;
	position: relative;

	.tablet-and-up-styles({
		padding: 20px 0 20px 20px;
	});

	&__body {
		display: grid;
		align-items: center;
		gap: 0 10px;
		grid-template-columns: auto 120px;
		grid-template-areas:
			"info    image"
			"button  button";
		width: 100%;

		.tablet-and-up-styles({
			flex-direction: column;
			flex: 1 1 50%;
			justify-content: space-between;
			align-items: flex-start;
			grid-template-rows: auto;
			grid-template-columns: minmax(200px, 250px) minmax(75px, 120px);
			grid-template-areas:
				"info   image"
				"button image";
		});

		.desktop-min-only-styles({
			grid-template-columns: minmax(50%, 230px) minmax(0, 220px);
		});
	}

	&__info {
		grid-area: info;
		margin-right: 10px;
		margin-bottom: auto;

		.tablet-and-up-styles({
			margin-right: 0;
			flex: unset;
		});
	}

	&__brand {
		color: #4C5271;
		font-weight: 500;
		margin-bottom: 0;
		font-size: .75rem;
		line-height: 1.5;

		.tablet-and-up-styles({
			font-size: 1rem;
			line-height: 2;
		});
	}

	&__heading {
		margin-bottom: 0;
	}

	&__title {
		font-weight: bold;
		font-size: 1.125rem;
		line-height: 1.3;

		.tablet-and-up-styles({
			font-size: 1.5rem;
			line-height: 1.15;
		});
	}

	&__subheading {
		font-size: 1rem;
		line-height: 1.3;
		margin-bottom: 0;
		margin-top: 5px;

		.tablet-and-up-styles({
			font-size: 1.125rem;
		});
	}

	&__price {
		&.price-wrapper {
			margin-top: 15px;
			margin-bottom: 0;

			.tablet-and-up-styles({
				margin-top: 20px;
			});
		}

		.price-alt-tip {
			z-index: 4;
		}
	}

	&__image {
		grid-area: image;
		position: relative;
		align-self: center;
		min-height: 140px;
		overflow: hidden;

		.tablet-and-up-styles({
			min-height: 184px;
		});


		.desktop-min-only-styles({
			min-height: 250px;
		});

		img {
			position: absolute;
			inset: 0;
			height: 100%;
			object-fit: cover;
			object-position: left;
		}
	}

	&__button {
		grid-area: button;
		align-self: end;
		white-space: nowrap;
		z-index: 3;
		&.ui-btn-lg {
			width: calc(100% - 15px);
			max-width: unset;
			min-width: unset;
			margin: 15px 15px 0 0;

			.tablet-and-up-styles({
				min-width: 210px;
				width: fit-content;
				max-width: 100%;
				margin: 20px 0 0 0;
			});
		}
	}

	&__link-mask {
		position: absolute;
		inset: 0;
		z-index: 2;
	}
}
