
@import "~lessRoot/_mixins.less";

.article {
	display: flex;
	color: black;
	text-decoration: none;
	border: 1px solid #E5E7F1;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;

	a {
		text-decoration: none;
	}

	&.top {
		flex-direction: column;
		border-top-right-radius: 0;
		border-bottom-left-radius: 8px;
		height: 100%;
		margin: 0;
	}

	&__title {
		&:hover {
			text-decoration: underline;
		}
	}

	&__image {
		width: 100%;
		height: 220px;
		position: relative;
		background-color: lightgray;
		background-size: cover;
		background-position: center;
	}

	&__content-type {
		position: absolute;
		top: 10px;
		left: 10px;

		span {
			display: inline-block;
			box-shadow: 0 0 3px #0000001A;
			background-color: #fff;
			color: #4C5271;
			border-radius: 10px;
			text-transform: uppercase;
			font-size: 14px;
			line-height: 1;
			letter-spacing: .42px;
			padding: 4px 8px;
			margin-right: 6px;
			margin-bottom: 6px;
			transition: all 0.2s ease-in-out;
		}

	}

	&__body {
		flex: 1;
		padding: 20px 15px 25px;
		position: relative;

		h3 {
			color: black;
			font-size: 24px;
			font-weight: bold;
			line-height: 28px;
			margin: 10px 0;
		}

		.post-excerpt {
			font-size: 18px;
			line-height: 22px;
			margin-bottom: 10px;

			/* Prevent text from overflowing 4 lines */
			text-overflow: ellipsis;
			white-space: normal;
			overflow: hidden;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
			display: -webkit-box;
		}
	}

	&__category {
		display: flex;
		gap: 4px;
		flex-wrap: wrap;

		a {
			color: #01305F;
			font-size: 14px;
			font-weight: bold;
			line-height: 16px;
			text-transform: uppercase;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__read-more {
		display: inline-block;
		color: #0054A4;
		font-size: 14px;
		border-bottom: 1px solid #0054A4;
		margin-bottom: 15px;
		padding: 0 2px 2px;

		&:hover {
			padding-bottom: 1px;
			border-bottom: 2px solid #0054A4;
		}
	}

	&__details {
		display: block;
		color: #4C5271;
		font-size: 12px;
		position: absolute;
		bottom: 13px;

	}

	.mobile-styles({
		display: flex;
		flex-direction: column;
		margin-bottom: 15px;
		border-top-right-radius: 0;
		border-bottom-left-radius: 8px;

		&__image {
			height: auto !important;
			padding-top: 56%;
			width: 100%;
		}

		&__body {
			padding: 15px 15px 20px;

			h3 {
				font-size: 20px;
				line-height: 24px;
				margin: 10px 0;
			}

			.post-excerpt {
				font-size: 16px;
				line-height: 20px;
			}
		}

		&__category {

			a {
				color: #01305F;
				font-size: 14px;
				font-weight: bold;
				line-height: 16px;
				text-transform: uppercase;

				&:hover {
					text-decoration: underline;
				}
			}
		}


		// &:not(.top){
		// }

	});

}
