
@import "~lessRoot/_mixins";

.dxp-carousel-widget-layout-c {
	--dsic-layout-content-padding: 14px;

	margin: 0 calc(-1 * var(--dxp-container-horizontal-padding));

	.tablet-and-up-styles({
		--dsic-layout-content-padding: 24px;
	});

	.desktop-min-only-styles({
		--dsic-layout-content-padding: 40px;
	});

	&__body {
		display: grid;
		--dsic-layout-c-overlap: 72px;

		/* https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_grid_layout/Grid_layout_using_named_grid_lines#implicit_grid_areas_from_named_lines */
		grid-template-columns: [viewport-start] var(--dxp-container-horizontal-padding) [content-start background-start] minmax(0, 1fr) [content-end background-end] var(--dxp-container-horizontal-padding) [viewport-end];
		grid-template-rows: [content-start background-start] auto [viewport-start content-end] var(--dsic-layout-c-overlap) [background-end] auto [viewport-end];;

		.tablet-and-up-styles({
			--dsic-layout-c-overlap: 133px;
		});

		.desktop-min-only-styles({
			grid-template-columns: var(--dxp-container-horizontal-padding) [content-start background-start viewport-start] minmax(0, 1fr) [content-end background-end viewport-end] var(--dxp-container-horizontal-padding);
		});
	}

	&__background {
		grid-area: background;
	}

	&__content {
		grid-area: content;
		display: grid;
		grid-template-columns: 1fr min-content;
		align-items: flex-end;
		padding: var(--dsic-layout-content-padding);
		padding-bottom: 20px;

		.tablet-and-up-styles({
			padding-bottom: 30px;
		});
	}

	&__title {
		margin-bottom: 6px;
		font-size: 1.375rem;
		font-weight: 500;
		line-height: 1.3182;

		.tablet-and-up-styles({
			font-size: 1.75rem;
			line-height: 1.3214;
			margin-bottom: 10px;
		});

	}

	&__text {
		font-size: 1.125rem;
		line-height: 1.3333;
		margin-bottom: 0;

		.tablet-and-up-styles({
			font-size: 1.375rem;
			line-height: 1.3182;
		});
	}

	&__link {
		margin-top: 20px;
		text-transform: uppercase;
		font-size: 1rem !important;
		padding: 10px 16px !important;
	}

	&__carousel-buttons {
		display: none;

		.desktop-min-only-styles({
			min-width: 102px;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			& > * {
				flex-shrink: 0;
			}
		});

		list-slider:not(:defined) &,
		&:not(:has([data-list-slider-action]:not([aria-disabled="true"]))) {
			> * {
				display: none;
			}
		}
	}

	/* also used for end-of-track ::after padding hack later in this file */
	--dsic-layout-c-horizontal-viewport-padding: calc(var(--dsic-layout-content-padding) + var(--dxp-container-horizontal-padding));

	[data-list-slider-viewport] {
		padding-left: var(--dsic-layout-c-horizontal-viewport-padding);

		.desktop-min-only-styles({
			padding-left: 0;
		});
	}

	&__viewport-wrapper {
		grid-area: viewport;

		.desktop-min-only-styles({
			padding: 0 var(--dsic-layout-content-padding);
		});
	}
}

.dxp-carousel-widget-layout-c-carousel {
	&__item {
		display: flex;
		width: var(--dsic-item-width);
	}

	&__track {
		padding-bottom: 14px;

		.desktop-min-only-styles({
			padding-bottom: 0;
		});

		/* padding doesn't apply to the end of an overflow scroll container, so we gotta fake it: */
		&::after {
			display: inline-block;
			content: '';
			width: var(--dsic-layout-c-horizontal-viewport-padding);
			margin-left: calc(var(--list-slider-item-gap) * -1);

			.desktop-min-only-styles({
				display: none;
			});
		}
	}
}
