
@import "~lessRoot/_mixins";
@import "~lessRoot/_variables";

.product-carousel-card {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: relative;
	background-color: white;
	border-radius: 6px;
	border: 1px solid tint(@CL, 60%);
	padding: 5px;

	&:hover {
		border: 2px solid @BB;
		padding: 4px;
	}

	&__heading {
		margin-bottom: 0;
		line-height: 1rem;
		flex: 1;
	}

	&__link {
		color: inherit;
		text-decoration: none;
		display: block;

		&::before {
			content: "";
			inset: 0;
			position: absolute;
		}
	}

	&__image {
		position: relative;
		width: 100%;
		aspect-ratio: 1;
		overflow: hidden;

		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__content {
		padding: 10px;
		padding-bottom: 20px;
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	&__price {
		margin-bottom: 10px;
		.mobile-styles({
			/deep/ .original-price {
				margin-bottom: 0;
			}
		});

		.desktop-min-only-styles({
			:deep(.price) {
				font-size: 1.875rem;
			}
		});
	}

	&__body {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	&__brand {
		font-size: 0.6875rem;
		line-height: 1;
		padding: 2px 0;
		font-weight: 500;
		color: #666666;
		display: block;

		.desktop-min-only-styles({
			font-size: 0.75rem;
		});

	}

	&__name {
		color: black;
		font-size: 0.75rem;
		line-height: 1.1667;
		font-weight: 500;
		-webkit-line-clamp: 3;

		.desktop-min-only-styles({
			font-size: 0.875rem;
			line-height: 1.1429;
		});
	}

	&__reviews {
		--review-stars-font-size: 18px;
		margin-top: 6px;
		.mobile-styles({
			display: none;
		});
	}

	&__sponsored {
		font-size: 0.75rem;
		line-height: 1.25;
		color: #4C5271;
		padding-top: 10px;
	}
}
