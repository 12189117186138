.default-widget-heading {
	font-size: 22px;
	line-height: 28px;
	color: @blue-gray;
	text-align: center;
	text-transform: uppercase;
	margin: 0 0 20px;

	/* prevent text touching the edge of screen for scrollable carousels */
	padding: 0 15px;
	.desktop-min-only-styles({
		padding: 0;
	});
}