
@import "~lessRoot/_mixins";

.featured-categories-widget {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&[data-fcw-mobile-layout=carousel] {
		.mobile-styles({
			grid-column: full-width; /* defined in DxpContainer.vue */
		});
	}

	&__title {
		// --page-title shares styles with DXP root header
		margin-bottom: 10px;

		&:not(&--page-title) {
			font-size: 1.375rem;
			line-height: 28px;
			color: shade(@CL, 40%);
			text-align: center;
			text-transform: uppercase;
			margin: 0;
		}
	}

	&__items {
		--fcw-columns: var(--fcw-columns--mobile);
		--fcw-gap: 6px;
		--fcw-max-item-width: 193px;

		display: grid;
		grid-template-columns: repeat(
			var(--fcw-columns),
			minmax(
				calc(var(--fcw-max-item-width) - 60px),
				var(--fcw-max-item-width)
			)
		);
		gap: var(--fcw-gap);
		justify-content: center;
		/* using padding instead of margin to avoid shadow getting cut off in mobile carousel variant */
		padding-top: 14px;
		width: 100%;

		[data-fcw-mobile-layout=carousel] & {
			.mobile-styles({
				display: flex;
				flex-wrap: nowrap;
				overflow-x: auto;
				justify-content: flex-start;

				& > * {
					width: 102px;
				}

				padding-left: var(--dxp-container-horizontal-padding);
				padding-bottom: 14px;

				&:after {
					content: '';
					display: block;
					width: calc(var(--dxp-container-horizontal-padding) - var(--fcw-gap));
					flex-shrink: 0;
				}
			});
		}

		.tablet-and-up-styles({
			--fcw-columns: var(--fcw-columns--tablet);
			padding-top: 16px;
		});

		.desktop-min-only-styles({
			--fcw-columns: var(--fcw-columns--desktop);
			--fcw-gap: 8px;
			--fcw-max-item-width: 135px;

			margin: 0 auto;
			padding-top: 20px;
		});

		@fcwDesktopLarge: 1470px;

		.styles-at-min(@fcwDesktopLarge, {
			--fcw-columns: var(--fcw-columns--desktop-large);
			--fcw-gap: 10px;
		});

		& > * {
			--fcw-shift-item-by-spaces: var(--fcw-shift-item-by-spaces--mobile);
			transform: translateX(calc(var(--fcw-shift-item-by-spaces, 0) * (100% + var(--fcw-gap))));

			.tablet-and-up-styles({
				--fcw-shift-item-by-spaces: var(--fcw-shift-item-by-spaces--tablet);
			});

			.desktop-min-only-styles({
				--fcw-shift-item-by-spaces: var(--fcw-shift-item-by-spaces--desktop);
			});

			.styles-at-min(@fcwDesktopLarge, {
				--fcw-shift-item-by-spaces: var(--fcw-shift-item-by-spaces--desktop-large);
			});
		}
	}

	&__toggle-button {
		display: inline-flex;
		align-items: center;
		justify-self: center;
		font-size: 0.75rem;
		margin-top: 10px;
		gap: 3px;

		.icon {
			font-size: 0.625rem;
		}

		.tablet-and-up-styles({
			display: none;
		});
	}
}

.featured-categories-widget-item {
	display: grid;
	grid-template-columns: 48px auto;
	gap: 7px;
	align-items: center;
	flex-shrink: 0;
	padding: 5px; // 6px in mockup, but that doesn't seem to count the border
	border: 1px solid tint(@CL, 60%);
	border-radius: 6px;
	font-size: 0.875rem;
	font-weight: 500;
	text-transform: capitalize;
	line-height: 1.1429;
	text-decoration: none;
	color: shade(@BB, 40%);
	box-shadow: 0px 0px 6px #0000001A;

	&--hidden-mobile {
		.mobile-styles({
			display: none;
		})
	}

	&__image {
		position: relative;
		padding-bottom: 100%;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			inset: 0;
			object-fit: contain;
		}
	}

	&__label {
		padding: 1.5px 0;
		/* not explicitly in the design, but it doesn't look great if the text happens to butt up close to the edge */
		padding-right: 8px;
	}


	&:hover {
		border: 2px solid @BB;
		padding: 4px;
	}

	[data-fcw-mobile-layout=carousel] & {
		.mobile-styles({
			.vertical-card();
		})
	}

	.desktop-min-only-styles({
		.vertical-card();
	});

	.vertical-card() {
		grid-template-columns: 1fr;
		grid-template-rows: min-content auto;
		gap: 6px;
		border-radius: 8px;
		text-align: center;
		align-items: start;
		padding-bottom: 7px; // 8px in mockup, but that doesn't seem to count the border

		&:hover {
			padding-bottom: 6px;
		}

		&__label {
			padding-right: 0;
		}
	}
}
