
@import "~lessRoot/_mixins";

.dxp-tile-grid-widget-section {
	display: flex;
	gap: var(--_tile-grid-gap);
	overflow-x: auto;
	container: tile-grid-section / inline-size;
	margin: 0 calc(-1 * var(--dxp-container-horizontal-padding));
	padding: 0 var(--dxp-container-horizontal-padding);

	.tablet-and-up-styles({
		flex-wrap: wrap;
	});

	&__row {
		display: flex;
		gap: var(--_tile-grid-gap);
		margin: 0 auto;
		flex: 1;

		.tablet-and-up-styles({
			width: 100%;
		});
	}

	/* mobile layout for primary/hero section; makes large card full width and wrap smaller items */
	.mobile-styles({
		&--primary &__row {
			flex-wrap: wrap;
		}

		&:not(&--primary) &__row {
			gap: calc(var(--_tile-grid-gap) / 2)
		}

		&--primary &__tile--primary {
			flex-basis: 100%;
		}
	});

	&__tile {
		container: tile-grid-widget-tile / inline-size;
		border-radius: 8px;
		overflow: hidden;
		background-color: gray;

		flex-basis: 0;
		flex-grow: calc(var(--_tile-aspect-ratio));
		aspect-ratio: var(--_tile-aspect-ratio);

		&--primary {
			--_tile-aspect-ratio: var(--_tile-grid-primary-aspect-ratio);
		}

		&--tall {
			--_tile-aspect-ratio: var(--_tile-grid-tall-aspect-ratio);
			width: calc(50vw - var(--_tile-grid-gap) / 2 - var(--dxp-container-horizontal-padding));
			@supports (container-type: inline-size) {
				width: calc(50cqw - var(--_tile-grid-gap) / 2);
			}
		}

		&--short {
			--_tile-aspect-ratio: var(--_tile-grid-short-aspect-ratio);
			width: calc(66vw - var(--_tile-grid-gap) / 2 - var(--dxp-container-horizontal-padding));
			@supports (container-type: inline-size) {
				width: calc(66cqw - var(--_tile-grid-gap) / 2);
			}
		}
	}
}
