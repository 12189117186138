
@import "~lessRoot/_mixins";
@import "~lessRoot/_variables";

.buy-it-again {
	max-width: 1440px;
	margin: 0 auto;

	&__heading-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 10px;
		padding: 0px 12px;

		.styles-at-min(1470px, {
			padding: 0px;
		});
	}

	&__heading {
		font-weight: bold;
		font-size: 1.5rem;
		line-height: 1.166;
		color: #191B26;
		margin: 0;
		padding: 0;
	}

	&__button-wrapper {
		display: flex;
		gap: 10px;
		margin-right: 40px;

		&__hidden {
			.styles-at-min(1340px, {
				display: none;
			});
		}
	}

	--bia-item-spacing: 12px;

	&__carousel {
		display: block;

		.list-slider-sized(1, 0.3);
		--list-slider-item-gap: var(--bia-item-spacing);

		.styles-at-min(481px, {
			--list-slider-full-items-per-slide: 1;
			--list-slider-item-peeking: .7;
		});

		.styles-at-min(600px, {
			--list-slider-full-items-per-slide: 2;
			--list-slider-item-peeking: 0;
		});

		.styles-at-min(800px, {
			--list-slider-full-items-per-slide: 2;
			--list-slider-item-peeking: 0.5;
		});

		.styles-at-min(930px, {
			--list-slider-full-items-per-slide: 3;
			--list-slider-item-peeking: 0;
		});

		.styles-at-min(1195px, {
			--list-slider-full-items-per-slide: 3;
			--list-slider-item-peeking: .5;
		});

		.styles-at-min(1340px, {
			--list-slider-full-items-per-slide: 4;
			--list-slider-item-peeking: 0;
		});
	}

	--bia-viewport-horizontal-padding: 12px;
	&__viewport {
		padding: 0 var(--bia-viewport-horizontal-padding);
		padding-bottom: 15px;

		.styles-at-min(1470px, {
			padding: 0;
		});
	}

	--bia-track-horizontal-padding: 15px;

	&__track {
		list-style-type: none;
		align-items: stretch;

		.desktop-min-only-styles({
			--bia-track-horizontal-padding: 0;
		});

		/* padding doesn't apply to the end of an overflow scroll container, so we gotta fake it: */
		&::after {
			display: inline-block;
			content: '';
			width: var(--bia-track-horizontal-padding);
			margin-left: calc(var(--list-slider-item-gap) * -1);
			.desktop-min-only-styles({
				display: none;
			});
		}
	}

	&__item {
		position: relative;
		margin: 0;
		border: 1px solid tint(@CL, 80%);
		border-radius: 8px;
		color: #191B26;
		padding: 12px;
		display: flex;
		gap: 10px;
		align-items: flex-start;
		height: 100%;
		outline-offset: -2px;
		min-height: 146px;

		@media (hover: hover) {
			&:hover:not(:has(.buy-it-again__atc-button:hover)) {
				outline: 2px solid @BB;
			}
		}

		&:active:not(:has(.buy-it-again__atc-button:hover)) {
			outline: 2px solid @BB;
		}

		.tablet-and-up-styles({
			align-items: center;
		});
	}

	&__image {
		position: relative;
		width: 100%;
		aspect-ratio: 1;
		overflow: hidden;
		max-width: 70px;
		min-width: 70px;

		.desktop-min-only-styles({
			max-width: 130px;
			min-width: 130px;
		});

		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__content-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
	}

	&__name {
		text-decoration: none;
		margin-bottom: 4px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* number of lines to show */
				line-clamp: 2;
		-webkit-box-orient: vertical;

		line-height: 1.25;
		font-size: 0.875rem;
		color: #191B26;

		&::after {
			content: "";
			position: absolute;
			inset: 0;
			z-index: 2;
		}

	}

	&__price {
		margin-bottom: 6px;
	}

	&__button {
		display: none;

		.desktop-min-only-styles({
			display: block;
		});
	}

	&__atc-wrapper {
		position: absolute;
		bottom: 10px;
		left: 10px;
		right: 10px;

		.tablet-and-up-styles({
			position: relative;
			bottom: 0;
			left: 0;
			right: 0;
		});
	}

	&__atc-spacer {
		padding-top: 41px;

		.tablet-and-up-styles({
			padding: 0px;
		});
	}

	&__atc-button {
		z-index: 3;
		width: 100%;
		text-transform: uppercase;
		text-wrap: nowrap;
	}
}
