
@import "../../less/_mixins.less";

.review-stars-component {
	display: flex;
	align-items: center;
	max-width: 300px;
	--review-stars-gap: 2px;

	&__total-reviews {
		font-size: 11px;
		margin-left: 4px;
		color: shade(@CL, 40%);
	}
}
