
@import "~lessRoot/_mixins";

.dxp-container {
	display: grid;
	width: 100%;
	margin: 0 auto;
	--dxp-container-horizontal-padding: 15px;
	grid-template-columns:
		[full-width-start] 1fr [padding-left-start] var(--dxp-container-horizontal-padding) [padding-left-end content-start] minmax(0, 1440px) [content-end padding-right-start] var(--dxp-container-horizontal-padding) [padding-right-end] 1fr [full-width-end];

	& > * {
		width: 100%;
		grid-column: content;

		&[data-full-width] {
			grid-column: full-width;
		}
	}

	.styles-at-min(1470px, {
		--dxp-container-horizontal-padding: 0px;
	});

}
