
@import "~lessRoot/_mixins";

.dxp-toyland-countdown-widget {

	&__state {
		border-radius: 8px;
		margin-top: 20px;
		height: 200px;
	}

	.coming-soon {
		padding: 24px 70px;
		background-color: #E5E7F2;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.starts-soon,
	.countdown,
	.started {
		padding: 24px 100px;
		background-color: #0F2D41;
		color: #E5E7F2;

		h2 {
			color: #E5E7F2;
			font-size: 48px;
			font-weight: 500;
			line-height: 56px;
			margin: 0;
			text-align: center;
			white-space: nowrap;
		}
	}

	.starts-soon {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 100px;
	}

	.countdown {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 120px;
	}

	&__timer {
		display: flex;
		text-align: center;
		gap: 10px;
		align-items: center;
	}

	&__time {
		font-size: 18px;
		font-weight: 500;
		line-height: 21px;

		.time-highlight {
			margin-bottom: 4px;
		}
	}

	&__separator {
		font-size: 48px;
		font-weight: 700;
		line-height: 1;
		padding-bottom: 30px;
	}

	.started {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	img {
		width: 100%;
		max-width: 420px;
	}

	.time-highlight {
		background-color: #0054A4;
		color: white;
		padding: 4px 10px;
		border-radius: 8px;
		font-size: 60px;
		font-weight: 700;
		line-height: 1;
		text-align: center;
		min-width: 60px;
		display: inline-block;
	}

	.tablet-styles({

		&__state {
			height: auto;
		}

		.coming-soon,
		.starts-soon,
		.countdown,
		.started {
			padding: 20px 60px;
		}

		.starts-soon,
		.countdown {
			gap: 60px;

			img {
				max-width: 320px;
			}

			h2 {
				font-size: 36px;
				line-height: 44px;
			}

			.time-highlight {
				padding: 4px 8px;
				font-size: 40px;
			}
		}

		&__timer {
			gap: 6px;
		}

		&__time {
			font-size: 16px;
			line-height: 20px;
		}
	});

	.mobile-styles({



		&__state {
			margin-top: 0px;
		}

		&__time {
			font-size: 10px;
			font-weight: 400;
			line-height: 12px;
		}

		&__separator {
			font-size: 28px;
			line-height: 32px;
			padding-bottom: 20px;
		}

		.coming-soon { 
			height: auto;

			h2 {
				font-size: 16px;
				line-height: 20px;
			}
		}

		.coming-soon,
		.started {
			img {
				max-width: 205px;
			}
		}

		.coming-soon,
		.starts-soon,
		.countdown,
		.started {
			padding: 20px 15px;
		}

		.starts-soon,
		.countdown {
			gap: 30px;

			img {
				max-width: 180px;
			}

			h2 {
				font-size: 16px;
				line-height: 18px;
				display: flex;
				align-items: center;
				gap: 5px;
			}

			.time-highlight {
				padding: 6px;
				font-size: 28px;
				min-width: 44px;
			}
		}

		&__timer {
			gap: 4px;
		}
	});

	.iphone-styles({
		.starts-soon,
		.countdown {
			gap: 0px;
			flex-direction: column;

			img {
				padding-bottom: 18px;
			}
		}
	});

}
