
@import "~lessRoot/_mixins";

.dxp-card-widget {
    display: flex;

    &__card-container {
        display: grid;
        grid-auto-columns: minmax(0, 1fr);
		grid-auto-rows: minmax(0, auto);
        grid-template-areas: var(--pcw-grid-areas--mobile);
        gap: 15px;
        width: 100%;

        .tablet-and-up-styles({
            grid-template-areas: var(--pcw-grid-areas--tablet);
        });

		.desktop-min-only-styles({
			gap: 24px;
		});

		@media only screen and (min-width: 1250px) {
			grid-template-areas: var(--pcw-grid-areas--desktop);
		}
    }
}
