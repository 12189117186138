
@import "~lessRoot/_mixins";

.circle-widget-item {
	--small-img-size: 90px;
	--medium-img-size: 150px;
	--large-img-size: 200px;

	--small-img-padding: 6px;
	--normal-img-padding: 10px;

	--small-font-size: 0.875rem;
	--medium-font-size: 1rem;
	--large-font-size: 1rem;

	&--responsive {
		--img-size: var(--small-img-size);
		--img-padding: var(--small-img-padding);
		--font-size: var(--small-font-size);

		.tablet-and-up-styles({
			--img-size: var(--medium-img-size);
			--img-padding: var(--normal-img-padding);
			--font-size: var(--medium-font-size);
		});

		.desktop-min-only-styles({
			--img-size: var(--large-img-size);
			--font-size: var(--large-font-size);
		});
	}

	&--small {
		--img-size: var(--small-img-size);
		--img-padding: var(--small-img-padding);
		--font-size: var(--small-font-size);
	}

	&--medium {
		--img-size: var(--medium-img-size);
		--img-padding: var(--normal-img-padding);
		--font-size: var(--medium-font-size);
	}

	&--large {
		--img-size: var(--large-img-size);
		--img-padding: var(--normal-img-padding);
		--font-size: var(--large-font-size);
	}

	display: inline-flex;
	align-items: center;
	flex-direction: column;
	font-size: var(--font-size);
	color: black;
	font-weight: 500;
	width: var(--blwi-width, var(--img-size)); // --blwi-width allows for outside control
	border-radius: 50%;

	&__label {
		text-align: center;
		&:not(&--hidden) {
			margin-top: 8px;
		}

		line-height: 16px;

		.desktop-min-only-styles({
			line-height: 18px;
		});
	}

	&__image-wrapper {
		padding: var(--img-padding);
		border-radius: 50%;
		border: 2px solid var(--blw-image-base-border-color);
		width: 100%;
	}

	&:hover &__image-wrapper, &:active &__image-wrapper {
		border-color: @BB;
		border-width: 3px;
		padding: ~"calc(var(--img-padding) - 1px)";
	}

	&:focus {
		outline: none;
	}

	&:focus &__image-wrapper {
		outline: var(--blwc--item-outline-width, 3px) solid @LB;
	}

	&__image-ratio {
		display: block;
		padding-bottom: 100%;
		position: relative;
		border-radius: 50%;
		overflow: hidden;
	}

	&__image {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		inset: 0;
	}
}
