
@import "~lessRoot/_mixins";

.dxp-tile-grid-widget {
	/* gap definition for spacing between sections (here) */
	/* and between tiles and section rows (TileGridSection.vue) */
	--_tile-grid-gap: 15px;
	.desktop-min-only-styles({
		--_tile-grid-gap: 24px;
	});

	&__sections {
		display: grid;
		gap: var(--_tile-grid-gap);
	}
}
