
@import '../../../less/_mixins.less';
.show-info-button {
	cursor: pointer;
	appearance: none;
	border: none;
	background: none;
	padding: 0;
	vertical-align: middle;
}
.info-window-text {
	font-size: 14px;
	padding: 27px 27px 21px 27px;
	text-align: left;
	background-color: #fff;
	color: #666666;
	.bold {
		font-weight: bold;
		color: #000;
	}
}
.shadow {
	// box-shadow: 0px 0 5px 0px #888888;
	border: 3px solid #D9D9D9;
	margin-right: -20px;
}
.info-circle {
	border: solid 2px @blue-main;
	border-radius: 50%;
	cursor: pointer;
	font-weight: bold;
	font-size: 18px;
	color: @blue-main;
	padding: 2px 8px;
}

/deep/ .blain-dropdown-arrow {
	margin-left: -3px;
}

@media screen and (max-width: @tablet) {
	.info-window-text {
		overflow: hidden;
		padding: 10px;
	}
}
