
@import "~lessRoot/_mixins";
@import "./shared/less/global.less";

//This should affect sitewide this classname
.vwo-shipping-control {
	//display:none;
}

//TODO: MAKE SURE TO SWAP THIS BEFORE LAUNCH.
.vwo-shipping-variant {
	display: none;
}

//Kids Clothing vwo test
//This widget ID is for the shop by categories widget
[widget-id="0c4181e3-2526-4554-b09c-b53ce98c20fc"] {
	display: none;
}


/* added to _Layout.cshtml's content element in DxpController */
.dxp-page-content-wrapper {
	#breadcrumbs {
		margin: 0;
		padding: 10px;

		.desktop-min-only-styles({
			padding: 10px 15px;
		})
	}
}

.dxp-root {
	container: dxp-root / inline-size;
	padding: 15px 0;

	&__brand-header {
		height: 5px;

		.tablet-and-up-styles({
			height: 45px;
		});
	}

	/* double ampersand intentional for specificity over mq styles */
	&&--no-top-padding {
		padding-top: 0;
	}

	--dxp-root-widget-spacing: 40px;
	.tablet-and-up-styles({
		--dxp-root-widget-spacing: 60px;
	});

	padding-bottom: var(--dxp-root-widget-spacing);

	&__default-header,
	.dxp-header {
		font-weight: bold;
		color: black;
		font-size: 1.5rem;
		line-height: 1.3333;
		text-align: center;

		.desktop-min-only-styles({
			font-size: 1.85rem;
		});
	}

	&__default-header {
		margin: 0;
		margin-bottom: calc(var(--dxp-root-widget-spacing) / 2);
	}

	&__widgets {
		display: grid;
		gap: var(--dxp-root-widget-spacing);
		grid-template-columns: 100%;
	}
}


/* card styles adapted from FeaturedCategoriesWidget.vue */
/* base layout adapted from homepage.less#scrollDeptNav */
/* this styles the dept/category nav at the top of the home page */
.dxp-home-page-department-nav {
	padding-top: 15px;
	margin: 0;

	--dxp-dept-nav-gap: 12px;

	--dxp-dept-nav-item-width: auto;
	.tablet-and-up-styles({
		// calculate item width such that, at full size, there will be 10 items visible with no overflow.
		// at lower sizes, like tablet, or if there are more than 10 on desktop, the items will maintain this
		// size
		@desired-items-visible: 10;
		@gap-adjustment: calc((var(--dxp-dept-nav-gap) * (@desired-items-visible - 1)) / @desired-items-visible);
		--dxp-dept-nav-item-width: calc(1440px / @desired-items-visible - @gap-adjustment);
	});


	.desktop-min-only-styles({
		margin: 0;
	});

	&__items {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		gap: var(--dxp-dept-nav-gap);
		padding: 10px var(--dxp-container-horizontal-padding);
		margin: 0 calc(var(--dxp-container-horizontal-padding) * -1);
	}
}

.dxp-home-page-department-nav-item {
	display: grid;
	grid-template-columns: 48px auto;
	gap: 7px;
	align-items: center;
	flex-shrink: 0;
	padding: 5px; // 6px in mockup, but that doesn't seem to count the border
	border: 1px solid tint(@CL, 60%);
	border-radius: 6px;
	text-decoration: none;
	box-shadow: 0px 0px 6px #0000001A;
	line-height: 1.1429;
	width: var(--dxp-dept-nav-item-width);

	.tablet-and-up-styles({
		grid-template-columns: 1fr;
		grid-template-rows: min-content auto;
		gap: 6px;
		border-radius: 8px;
		text-align: center;
		align-items: start;
		padding-bottom: 7px; // 8px in mockup, but that doesn't seem to count the border
	});

	&:hover {
		border: 2px solid @BB;
		padding: 4px;
		.tablet-and-up-styles({
			padding-bottom: 6px;
		});
	}

	&__image {
		position: relative;
		padding-bottom: 100%;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			inset: 0;
			object-fit: contain;
		}
	}

	&__label {
		font-size: 0.875rem;
		font-weight: 500;
		text-transform: capitalize;
		line-height: inherit;
		color: shade(@BB, 40%);
		padding: 1.5px 0;
		/* not explicitly in the design, but it doesn't look great if the text happens to butt up close to the edge */
		padding-right: 8px;
		margin: 0;

		.tablet-and-up-styles({
			padding-right: 0;
		});
	}
}
