
@import "~lessRoot/_mixins";
@import "~lessRoot/_variables";


.carousel-widget-indicators {
	display: flex;
	justify-content: center;
	align-items: center;

	&__dot {
		background-color: @CL;
		border-radius: 50%;
		border: 0;
		cursor: pointer;
		width: 6px;
		height: 6px;
		margin: 0 5px;
		padding: 0;

		&--active {
			background-color: @DB;
			width: 8px;
			height: 8px;
		}
		
	}

	.tablet-styles({
		display: none;
	});
}
