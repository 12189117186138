
@import "~lessRoot/_mixins";

.dxp-tile-grid-widget-image-tile {
	position: relative;
	text-decoration: none;
	color: inherit;

	&__picture {
		&, & > img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
		}

		img {
			object-fit: cover;
		}

		img[alt] {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

}
