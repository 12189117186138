
@import "~lessRoot/_mixins";

.dxp-win-wishlist {
	height: 130px;
	overflow: hidden;
	background-image: var(--wyw-bg-image-mobile);
	background-size: cover;
	display: flex;
	position: relative;
	justify-content: space-between;
	border: 1px solid #CBD0E4;
	border-radius: 8px;
	padding: 15px 15px 15px 10px;

	.tablet-and-up-styles({
		height: 150px;
		background-image: var(--wyw-bg-image);
		padding: 0 60px 0 0;
	});

	&__phone {
		display: none;

		.tablet-and-up-styles({
			display: block;
		});
	}

	&__content {
		display: flex;
		column-gap: 10px;

		.tablet-and-up-styles({
			column-gap: 15px;
		})
	}

	&__icon {
		font-family: 'BlainsIcons' !important;
		color: #AA141A;
		font-size: 100px;
		align-self: center;

		.tablet-and-up-styles({
			padding: 20px 20px 15px 20px;
			font-size: 110px;
		});

		.ms-styles({
			font-size: 50px;
		});
	}

	&__text-block {
		margin: auto 0;
	}

	&__title {
		font-weight: 700;
		font-size: 1.375rem;
		line-height: 26px;
		margin-bottom: 4px;

		.tablet-and-up-styles({
			font-size: 1.75rem;
			line-height: 32px;
			margin-bottom: 8px;
		});

		a {
			color: inherit;
			text-decoration: inherit;
		}
	}

	&__subtitle {
		margin-bottom: 2px;
		font-size: 1rem;
		line-height: 20px;
		font-weight: 500;

		.tablet-and-up-styles({
			margin-bottom: 8px;
			font-size: 1.25rem;
			line-height: 24px;
		});
	}

	&__blurb {
		font-size: 0.875rem;
		line-height: 16px;
		margin-bottom: 0;

		.tablet-and-up-styles({
			font-size: 1.125rem;
		});
	}

	&__link-mask {
		position: absolute;
		inset: 0;
		z-index: 2;
	}
}
