
@import '../../less/_mixins.less';
.dropdown-wrapper {
	position: relative;
}
.blain-dropdown-arrow {
	position: absolute;
	transform: rotate(45deg);
	z-index: -1;
	box-shadow: 0 0 10px 0 rgba(0,0,0,.25);
	&.bordered {
		border: 3px solid #D9D9D9;
		box-shadow: none;
		z-index: 1;
		&.top {
			border-left: none;
			border-top: none;
		}
		&.bottom {
			border-right: none;
			border-bottom: none;
		}
	}
}
.blain-dropdown {
	position: absolute;
	background: #fff;
	color: #000;
	z-index: 9999;
	border-radius: 4px;
	color: #FFF;
	font-size: 16px;
	max-width: 320px;
	line-height: 1.4em;
	text-align: center;
	// max-width serves as the actual width for responsive purposes, but we still want to be able
	// to extend beyond wrapped element so we set an arbitrarily large width here
	width: 10000px;
	.tablet-styles({
		max-width: 300px;
	});
	span {
		position: relative;
	}
	&.shadow {
		box-shadow: 0 0 10px 0 rgba(0,0,0,.25);
	}
	.transparent.full-width {
		position: absolute;
		top: -16px;
		left: 0;
		right: 0;
		width: 100%;
		height: 16px;
		background: transparent;
	}
}
.dropdown-menu {
	background: #fff;
	color: #000;
	z-index: 9999;
}
.dropdown-host {
	cursor: pointer;
	position: relative;
	.host-content {
		display: flex;
		flex-direction: row;
		align-items: center;
		.icon {
			font-size: 26px;
			padding-bottom: 3px;
		}
		.toggle-icon {
			font-size: 7px;
			margin-left: 5px;
		}
		.host-text {
			white-space: nowrap;
		}
	}
	&.default {
		.host-content {
			color: @DB;
			.icon {
				color: @LB;
			}
			.host-text {
				font-size: 14px;
				font-weight: 500;
				color: @DB;
			}
			.toggle-icon {
				color: @LB;
				padding-top: 3px;
			}
		}
	}
	&.black-white {
		.host-content {
			color: #fff;
			.icon {
				color: #BDC6DC;
				margin-right: 2px;
				font-size: 22px;
				text-decoration: none;
			}
			.host-text {
				font-weight: 500;
				font-size: 12px;
			}
			&:hover {
				.host-text {
					text-decoration: underline;
					color: #7CB7DC;
				}
				.icon {
					color: #7CB7DC;
				}
			}
		}
	}
	&.navbar-item-blue {
		.host-content {
			color: @DB;
			.icon {
				display: none;
			}
			.host-text {
				font-weight: 500;
				font-size: 14px;
				.styles-at(1200px, {
					font-size: 13px;
				});
			}
			&:hover {
				.host-text {
					text-decoration: none;
					color: @DB;
				}
			}
		}
	}
}
.dropdown-header {
	background-color: #fff;
	padding: 5px 3px 0px 5px;
	display: flex;
	justify-content: space-between;
	.header-text {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: 500;
		color: #656E97;
		letter-spacing: 0.56px;
		.text {
			margin-top: 5px;
		}
		.icon {
			color: #98A1CA;
			font-size: 24px;
			margin-right: 5px;
		}
	}
}
.fade-enter-active, .fade-leave-active {
	transition: opacity 0.4s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}
