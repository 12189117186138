
@import "~lessRoot/_mixins";

.dxp-banner-widget {
	display: flex;
	flex-direction: row-reverse;
	height: min-content;
	position: relative;
	text-decoration: none;

	&--left-aligned {
		flex-direction: row;
	}

    &__body {
		height: fit-content;
		width: 160px;
		padding: 14px 10px 14px 14px;

		.mobile-and-up-styles({
			width: 280px;
		});

		.tablet-and-up-styles({
			padding: 24px 12px 24px 24px;
		});

		.desktop-min-only-styles({
			width: 500px;
			padding: 40px;
		});
    }

	&__heading {
		font-size: 1.125rem;
		line-height: 1.25;
		font-weight: 500;
		margin-bottom: 0;

		.tablet-and-up-styles({
			font-size: 1.375rem;
		});

		.desktop-min-only-styles({
			font-size: 1.75rem;
		});
	}

	&__subheading {
		font-size: .875rem;
		line-height: 1.3;
		margin-top: 6px;
		margin-bottom: 0;

		.desktop-min-only-styles({
			font-size: 1.375rem;
			margin-top: 10px;
		});
	}

	&__button {
		margin-top: 20px;
		text-transform: uppercase;
		z-index: 3;
		position: relative;

		&.ui-btn {
			width: fit-content;
		}

		.ms-styles({
			position: absolute;
			width: 100% !important;
			height: 100% !important;
			top: 0;
			left: 0;

			&.ui-btn,
			&.ui-btn:hover {
				color: rgba(0,0,0,0);
				background-color: rgba(0,0,0,0);
				border: none;
				margin: 0;
				max-width: unset;
			}
		});

		.desktop-min-only-styles({
			margin-top: 30px;
		});
	}

	&__image {
		flex: 1 1;
		position: relative;
		z-index: -1;

		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
    }

	&__link-mask {
		position: absolute;
		inset: 0;
		z-index: 2;
	}
}
