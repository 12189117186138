
@import "~lessRoot/_mixins.less";
.banner-x {
	&__image {
		width: 100%;
		display: none;

		&--mobile {
			aspect-ratio: 420/120;
			display: inline;

			.tablet-and-up-styles({
				display: none;
			});
		}

		&--tablet {
			aspect-ratio: 900/120;
			.tablet-and-up-styles({
				display: block;
			});

			.desktop-min-only-styles({
				display: none;
			});
		}

		&--desktop {
			aspect-ratio: 1440/100;
			.desktop-min-only-styles({
				display: block;
			});
		}
	}

	.sponsored-callout {
		margin-bottom: 10px;
		color: @blue-gray;
		font-size: 0.75rem;
		text-align: right;
		line-height: 1.25;
		margin-top: 4px;
	}
}

