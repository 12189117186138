
@import "~lessRoot/_mixins";

.dxp-story-carousel-story {
	border: 1px solid tint(@CL, 60%);
	border-radius: 5px;
	background-color: white;
	overflow: hidden;
	position: relative;

	&:focus-within, &:hover {
		.focus-outline();
		outline-style: solid;
		outline-width: 3px;
		outline-offset: -3px;
	}

	&:focus-within {
		outline-color: @LB;
	}

	&:hover {
		outline-color: @BB;
	}

	&__image {
		display: block;
		img {
			display: block;
			width: 100%;
			aspect-ratio: 214 / 230;
			object-fit: cover;
		}
	}

	&__body {
		color: black;
		padding: 10px 12px 12px;

		.desktop-min-only-styles({
			padding: 14px;
		});
	}

	&__title {
		margin-bottom: 4px;
		font-size: 1.125rem;
		font-weight: bold;
		line-height: 1.3333;

		.desktop-min-only-styles({
			margin-bottom: 6px;
			font-size: 1.5rem;
		});

		a {
			text-decoration: none;
			color: black;

			&::after {
				content: '';
				position: absolute;
				inset: 0;
			}

			&:focus {
				outline: none;
			}
		}
	}

	&__text {
		margin: 0;
		font-size: 0.875rem;
		line-height: 1.2857;

		.desktop-min-only-styles({
			font-size: 1.125rem;
			line-height: 1.3333;
		});

	}
}
