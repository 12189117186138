
@import "~lessRoot/_mixins.less";

.original-price {
	color: #4C5271;
	line-height: 1;

	.original-price-span {
		text-decoration: line-through;
		margin: 0 2px;
	}
}

.automotive-price {
	.original-price {
		color: shade(@CL, 40%);
		font-size: 16px;
		font-weight: normal;
		margin: 4px 0 2px;

		span {
			text-decoration: line-through;
			margin: 0 2px;
		}

		.tablet-styles({
			font-size: 12px;
		});
	}
}

.price-wrapper {
	&__inner {
		display: flex;
		align-items: center;

		.tablet-and-up-styles({
			align-items: stretch;
		})
	}

	&:not(.sized) {
		font-size: 1.25rem;
		.desktop-min-only-styles({
			font-size: 1.8rem;
		})
	}

	&.sized {
		&.small {
			font-size: 1.25rem;
		}
		&.medium {
			font-size: 1.8rem;
		}
		&.large {
			font-size: 1.875rem;
		}

	}
}
.price {
	div {
		font-size: 1em;
		font-weight: 500;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
		.dash {
			align-self: center;
			padding: 0 3px 0 3px;
			font-size: 0.875rem;
		}
	}
}
.price-saved {
	color: @DR;
	font-weight: 500;
	max-width: 300px;
	margin-bottom: 4px;
	line-height: 1;
}
.group-price-varies {
	font-size: 1rem;
	font-weight: 500;
}
.price-alt {
	display: flex;
	flex-direction: column;
	font-size: 1rem;
	font-weight: 500;
	line-height: normal;

	&__last-word {
		margin-top: 5px;
		white-space: nowrap;
		display: inline-flex;
		color: @BB;
		font-size: 0.75rem;
		line-height: 0.9375rem;
	}
}
.price-quote {
	color: #4C5271;
	font-size: 1em;
	text-align: right;
}
.link-to-modal {
	color: #014A77;
	text-decoration: underline;
	cursor: pointer;
}
.price-comment {
	display: flex;
	align-self: flex-end;
	font-weight: 500;
	font-size: 0.6em;
	line-height: 1;
	margin-bottom: 2px;
	margin-left: 2px;
	.mobile-styles({
		margin: 0;
	});

	.tablet-and-up-styles({
		font-size: 0.75rem;
	});

	.desktop-min-only-styles({
		margin-bottom: 4px;
	});

	.small & {
		font-size: 0.6em;
		margin-bottom: 2px;
	}
}

.price-alt-tip {
	display: inline-block;
}

.price-prefix {
	font-size: 0.875rem;
	min-height: 0;

	.desktop-min-only-styles({
		font-size: 1rem;
	});

	.small & {
		font-size: 0.875rem;
	}

	.medium & {
		font-size: 0.875rem;
	}
}

.original-price,
.price-saved {
	font-size: 0.75rem;
	line-height: 15px;
}

.price-prefix {
	font-weight: 500;
	color: #4C5271;
	margin-bottom: 2px;
	line-height: 1.25rem;

	&__text {
		&--group {
			.tablet-only-styles({
				display: block;
				margin-bottom: 6px; // tweak to align with SALE text
			})
		}

		&--automotive {
			font-size: 18px;
			font-weight: bold;

			span {
				font-size: 16px;
				font-weight: normal;
			}

			.tablet-styles({
				font-size: 12px;

				span {
					font-size: 12px;
				}
			});
		}
	}
}
.promo-text {
	color: @BR;
}

.price-info {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-top: 2px;
}

@media screen and (max-width: @phablet) {
	.price-wrapper {
		margin-top: 0;
	}
}

@media screen and (max-width: @mobile-small) {
	.price-prefix {
		margin-right: 3px;
	}
}
