
@import "~lessRoot/_mixins.less";

	.product-price-display {
		line-height: 1.5rem;
		display: flex;
		line-height: 1;
		align-items: flex-start;
		margin-bottom: 2px;
		font-size: 1.25rem;
		font-weight: 600;

		.desktop-min-only-styles({
			font-size: 1.5rem;
		});

		.dollar-sign {
			font-size: 0.75rem;
			line-height: 1rem;
		}
		.price-cents {
			font-size: 0.75rem;
			line-height: 1rem;
		}
	}
