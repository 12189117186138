
@import "~lessRoot/_mixins";

.dxp-item-carousel-widget {
	--list-slider-item-gap: 10px;
	--dsic-item-width: 150px; /* 130px in mockup. double check this tweak w/ Madeline */

	.desktop-min-only-styles({
		--dsic-item-width: 180px;
	});
}
