
@import "~lessRoot/_mixins";

.dxp-carousel-widget-layout-b {
	--dsic-horizontal-viewport-padding: calc(14px + var(--dxp-container-horizontal-padding));

	margin: 0 calc(-1 * var(--dxp-container-horizontal-padding));

	.tablet-and-up-styles({
		--dsic-horizontal-viewport-padding: 0;
		margin: 0;
	});

	&__body {
		display: grid;
		grid-template-columns: 15px 1fr 15px;
		grid-template-areas:
			".        content  .       "
			"carousel carousel carousel";
		gap: 10px 0;

		.tablet-and-up-styles({
			grid-template-columns: 420px 1fr;
			grid-template-areas:
				"content carousel";
			gap: 10px;
		});

		.desktop-min-only-styles({
			grid-template-areas:
				".       carousel-buttons"
				"content carousel";
			gap: 15px 20px;
		});
	}

	[data-list-slider-viewport] {
		grid-area: carousel;
		padding-left: var(--dsic-horizontal-viewport-padding);
	}

	&__content {
		grid-area: content;
		padding: 14px 14px 20px;
		.tablet-and-up-styles({
			margin-bottom: 14px;
			padding: 40px 24px;
		});

		.desktop-min-only-styles({
			padding: 40px;
			margin-bottom: 0;
		});
	}

	&__title {
		margin-bottom: 6px;
		font-size: 1.375rem;
		font-weight: 500;
		line-height: 1.3182;

		.tablet-and-up-styles({
			font-size: 1.75rem;
			line-height: 1.3214;
			margin-bottom: 10px;
		});

	}

	&__text {
		font-size: 1.125rem;
		line-height: 1.3333;
		margin-bottom: 0;

		.tablet-and-up-styles({
			font-size: 1.375rem;
			line-height: 1.3182;
		});
	}

	&__link {
		margin-top: 20px;
		text-transform: uppercase;
		font-size: 1rem !important;
		padding: 10px 16px !important;
	}

	&__carousel-buttons {
		display: none;

		.desktop-min-only-styles({
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			gap: 10px;
			grid-area: carousel-buttons;
			margin-right: 40px;
			min-height: 46px;

			& > * {
				flex-shrink: 0;
			}
		});

		list-slider:not(:defined) &,
		&:not(:has([data-list-slider-action]:not([aria-disabled="true"]))) {
			> * {
				display: none;
			}
		}
	}
}

.dxp-carousel-widget-layout-b-carousel {
	&__item {
		display: flex;
		width: var(--dsic-item-width);
	}

	&__track {
		padding-bottom: 14px;

		.desktop-min-only-styles({
			padding-bottom: 0;
		});

		&::after {
			display: inline-block;
			content: '';
			width: var(--dsic-horizontal-viewport-padding);
			margin-left: calc(var(--list-slider-item-gap) * -1);
			.tablet-and-up-styles({
				display: none;
			});
		}
	}
}
