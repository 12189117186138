
@import "~lessRoot/_variables.less";
@import "~lessRoot/_mixins.less";

.blog-carousel-widget {
	max-width: 1440px;
	padding: 0 15px;
	margin: 0 auto;
	position: relative;

	.styles-at-min(1470px, {
		padding: 0;
	});

	.tablet-styles({
		padding: 0;
	});

	&__heading {
		border-bottom: 1px solid #B2B8D7;
		display: flex;
		align-items: baseline;
    	gap: 10px;
		padding-bottom: 4px;

		h2 {
			color: #4C5271;
			font-size: 28px;
			font-weight: 700;
			margin-bottom: 0;

		}

		a {
			font-size: 14px;
			font-weight: 500;
			color: @BB;
			padding-left: 15px;
			text-decoration: none;
			border-bottom: 1px solid @BB;
			padding: 0 2px 2px;

			span {
				font-size: 10px;
			}

			&:hover {
				border-bottom: 2px solid @BB;
			}
		}

		.tablet-styles({
			margin: 0 15px;
		});

		.mobile-styles({
			flex-direction: column;

			h2 {
				font-size: 20px;
			}

			a {
				padding: 0;
				margin-bottom: 6px;

				&:hover {
					border-bottom: 2px solid @BB;
					margin-bottom: 5px;
				}
			}
		});
	}


	&__viewport {
		grid-area: viewport;
		margin: 0px -10px;

		.list-slider(4);

		.styles-at(1300px, {
			--list-slider-items-per-slide: 4;
		});

		.styles-at(1100px, {
			--list-slider-items-per-slide: 3;
		});

		.tablet-styles({
			.list-slider-scroll();
			display: flex;
			margin: 0;
		});

		.mobile-styles({
			--list-slider-items-per-slide: 2.4;
		});

		.styles-at(600px, {
			--list-slider-items-per-slide: 1.8;
		});

		.ms-styles({
			--list-slider-items-per-slide: 1.1;
		});
	}

	--tcw-item-spacing: 22px;
	--tcw-item-horizontal-padding: calc(var(--tcw-item-spacing) / 2);
	--tcw-track-horizontal-padding: calc(15px - var(--tcw-item-horizontal-padding));

	&__track {
		list-style-type: none;
		padding: 14px var(--tcw-track-horizontal-padding);
		align-items: stretch;

		.tablet-and-up-styles({
			padding-top: 20px;
		});

		.desktop-min-only-styles({
			--tcw-track-horizontal-padding: 0;
		});

		/* padding doesn't apply to the end of an overflow scroll container, so we gotta fake it: */
		&::after {
			display: inline-block;
			content: '';
			width: var(--tcw-track-horizontal-padding);
			.desktop-min-only-styles({
				display: none;
			});
		}
	}

	&__item {
		padding: 0 var(--tcw-item-horizontal-padding);
		margin: 0;

		.tablet-styles({
			height: 100%;
		});
	}

	&__controls {
		position: absolute;
		top: -20px;
		right: 15px;
		display: flex;
		gap: 15px;

		.styles-at-min(1470px, {
			right: 0;
		});
	}

	&__button {
		display: none;

		.desktop-min-only-styles({
			display: block;
		});

		&--previous {
			grid-area: previous;
		}

		&--next {
			grid-area: next;
		}
	}

}
