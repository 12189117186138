
@import "~lessRoot/_mixins";

//TODO: can maybe delete this.
.dxp-toyland-seo-widget {
    padding: 0 100px;
    text-align: center;

    p {
        font-size: 16px;
    }

    &__first-p {
        font-size: 22px !important;
    }

    .mobile-styles({
        padding: 0;

        &__first-p {
            font-size: 16px !important;
        }

        p {
            font-size: 14px;
        }
    });
}
