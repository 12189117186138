
@import "~lessRoot/_mixins";

.dxp-story-carousel-widget {
	--dsic-item-width: 216px;
	--list-slider-item-gap: 10px;

	.tablet-and-up-styles({
		--list-slider-item-gap: 24px;
	});

	.desktop-min-only-styles({
		--dsic-item-width: 270px;
	});

	.styles-at-min(1290px, {
		:deep(.dxp-carousel-widget-layout-a-carousel) {
			.list-slider-sized(3);
		}
	});
}
