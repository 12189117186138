
@import "~lessRoot/_mixins";
@import "~lessRoot/_variables";

.default-widget-heading {
	margin: 0;
}

.trending-carousel-widget {
	max-width: 1440px;
	margin: 0 auto;

	.desktop-min-only-styles({
		padding: 0 15px;
	});

	.styles-at-min(1440px, {
		padding: 0 30px;
	});

	--tcw-item-spacing: 10px;
	.tablet-and-up-styles({
		--tcw-item-spacing: 22px;
	});

	&__carousel {
		.desktop-min-only-styles({
			display: grid;
			gap: 20px;
			grid-template-areas: "previous viewport next";
			grid-template-columns: min-content 1fr min-content;
			align-items: center;
		});

		--list-slider-item-gap: var(--tcw-item-spacing);


		.tablet-and-up-styles({
			.list-slider-sized(1, 0.5);
			--list-slider-full-items-per-slide: 3;
			--list-slider-item-peeking: 0.5;
		});

		.styles-at-min(1101px, {
			--list-slider-full-items-per-slide: 5;
			--list-slider-item-peeking: 0;
		});

		.styles-at-min(1301px, {
			--list-slider-full-items-per-slide: 6;
			--list-slider-item-peeking: 0;
		});
	}

	--tcw-viewport-horizontal-padding: 0;
	&__viewport {
		grid-area: viewport;
		padding: 0 var(--tcw-viewport-horizontal-padding);
	}

	--tcw-track-horizontal-padding: 15px;

	&__track {
		list-style-type: none;
		padding: 14px var(--tcw-track-horizontal-padding);
		align-items: stretch;

		.tablet-and-up-styles({
			padding-top: 20px;
		});

		.desktop-min-only-styles({
			--tcw-track-horizontal-padding: 0;
		});

		/* padding doesn't apply to the end of an overflow scroll container, so we gotta fake it: */
		&::after {
			display: inline-block;
			content: '';
			width: var(--tcw-track-horizontal-padding);
			margin-left: calc(var(--list-slider-item-gap) * -1);
			.desktop-min-only-styles({
				display: none;
			});
		}
	}

	&__item {
		min-width: 150px;

		margin: 0;
		/* padding: 0 var(--tcw-item-horizontal-padding); */

		.tablet-styles({
			height: 100%;
		});
	}

	&__button {
		display: none;

		.desktop-min-only-styles({
			display: block;
		});

		&--previous {
			grid-area: previous;
		}

		&--next {
			grid-area: next;
		}
	}
}
