
@import "~lessRoot/_mixins";

.dxp-carousel-widget-layout-a {
	max-width: 100%;
	.tablet-and-up-styles({
		display: grid;
		align-content: stretch;
		grid-template-columns: 250px minmax(0, auto);
	});

	.desktop-min-only-styles({
		grid-template-columns: ~"minmax(auto, 400px)" minmax(0, auto);
	});

	.styles-at-min(1440px, {
		grid-template-columns: ~"minmax(auto, 500px)" minmax(0, auto);
	});

	&__image {
		display: block;
		position: relative;
		aspect-ratio: 1;
		.tablet-and-up-styles({
			aspect-ratio: auto;
		});

		img {
			position: absolute;
			inset: 0;
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__body {
		display: grid;
		grid-template-areas:
			"content"
			"carousel";
		gap: 20px;
		padding: 14px;

		.tablet-and-up-styles({
			gap: 30px;
			padding: 40px 24px 30px 24px;
			grid-template-columns: 1fr min-content;
			grid-template-areas:
				"content  carousel-buttons"
				"carousel carousel";
		});

		.desktop-min-only-styles({
			padding: 40px 42px 30px 40px;
		});
	}

	[data-list-slider-viewport] {
		grid-area: carousel;
	}

	&__content {
		grid-area: content;
	}

	&__title {
		margin-bottom: 6px;
		font-size: 1.375rem;
		font-weight: 500;
		line-height: 1.3182;

		.tablet-and-up-styles({
			font-size: 1.75rem;
			line-height: 1.3214;
			margin-bottom: 10px;
		});

	}

	&__text {
		font-size: 1.125rem;
		line-height: 1.3333;
		margin-bottom: 0;

		.tablet-and-up-styles({
			font-size: 1.375rem;
			line-height: 1.3182;
		});
	}

	&__link {
		margin-top: 20px;
		text-transform: uppercase;
		font-size: 1rem !important;
		padding: 10px 16px !important;
	}

	&__carousel-buttons {
		display: none;

		.desktop-min-only-styles({
			min-width: 102px;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			grid-area: carousel-buttons;

			& > * {
				flex-shrink: 0;
			}
		});

		list-slider:not(:defined) &,
		&:not(:has([data-list-slider-action]:not([aria-disabled="true"]))) {
			> * {
				display: none;
			}
		}
	}
}

.dxp-carousel-widget-layout-a-carousel {
	&__item {
		display: flex;
		width: var(--dsic-item-width);

		> * {
			width: 100%;
		}
	}

	&__track {
		padding-bottom: 14px;

		.desktop-min-only-styles({
			padding-bottom: 0;
		});
	}
}
