
@import "~lessRoot/_mixins";

.brand-logos-widget-carousel {
	max-width: 1440px;
	margin: 0 auto;
	--blwi-width: 100%;
	--blwc-item-outline-width: 3px;
	--blwc-item-gap: 0.5rem;

	.tablet-and-up-styles({
		--blwc-item-gap: 1rem;
	});

	.desktop-min-only-styles({
		display: grid;
		grid-template-areas: "previous viewport next";
		gap: calc(30px - var(--blwc-item-gap) / 2);
		grid-template-columns: min-content 1fr min-content;
		align-items: center;
		padding: 0 15px;
	});

	.styles-at-min(1440px, {
		padding: 0 30px;
	});

	&__viewport {
		grid-area: viewport;
		.list-slider(6);

		.styles-at(1280px, {
			--list-slider-items-per-slide: 5;
		});

		.styles-at(1100px, {
			--list-slider-items-per-slide: 4;
		});

		.tablet-styles({
			--list-slider-items-per-slide: 4.33;
			--blwc-item-gap: 1.25rem;
			.list-slider-scroll();
		});

		.mobile-styles({
			--blwc-item-gap: 0.5rem;
			--list-slider-items-per-slide: 3.15;
		});
	}

	&__track {
		list-style-type: none;

		--blwc-track-horizontal-padding: calc(15px - var(--blwc-item-gap) / 2);

		padding: 0 var(--blwc-track-horizontal-padding);
		padding-bottom: 14px; // spacing for scroll bar

		.desktop-min-only-styles({
			--blwc-track-horizontal-padding: 0;
			padding-bottom: 0;
		});

		/* padding doesn't apply to the end of an overflow scroll container, so we gotta fake it: */
		&::after {
			display: inline-block;
			content: '';
			width: var(--blwc-track-horizontal-padding);
			.desktop-min-only-styles({
				display: none;
			});
		}

	}

	&__item {
		padding: 0 calc(var(--blwc-item-gap) / 2);
		margin: var(--blwc-item-outline-width) 0;
		/* fixes a bizarre issue in chrome and safari where .visually-hidden's position: absolute makes */
		/* the page act as if the carousel's overflow container widen's the page to full width and */
		/* causes page-level horizontal scroll */
		position: relative;
	}

	&__button {
		display: none;

		.desktop-min-only-styles({
			display: block;
		});

		&--previous {
			grid-area: previous;
		}

		&--next {
			grid-area: next;
		}
	}
}
