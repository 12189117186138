
@import "~lessRoot/_mixins.less";

.show-info-button {
	cursor: pointer;
	appearance: none;
	border: none;
	background: none;
	padding: 0;
	vertical-align: middle;
	color: @BB;
	font-weight: 500;
}
.nowrap {
	white-space: nowrap;
}
.info-icon {
	width: initial;
	animation: none;
	opacity: 1;
	margin-left: 3px; // leave 1px for the hide-aria span
}
.info-window-text {
	font-size: 14px;
	padding: 27px 27px 21px 27px;
	text-align: left;
	background-color: #fff;
	color: #666666;
	white-space: normal;
	font-weight: 400;
	.bold {
		font-weight: bold;
		color: #000;
	}
}
.shadow {
	border: 1px solid #D9D9D9;
	margin-right: -20px;
	border-radius: 8px;
}
/deep/ .shadow {
	border-radius: 8px;
}
.info-circle {
	border: solid 2px @blue-main;
	border-radius: 50%;
	cursor: pointer;
	font-weight: bold;
	font-size: 18px;
	color: @blue-main;
	padding: 2px 8px;
}

/deep/ .blain-dropdown {
	z-index: 3; // make it go behind the filter header when scrolling, especially on mobile
}

/deep/ .blain-dropdown-arrow {
	margin-left: -3px;
	border-width: 1px !important;
}

/deep/ .blain-dropdown.shadow {
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: @tablet) {
	.info-window-text {
		overflow: hidden;
		padding: 10px;
		white-space: normal;
	}
}
