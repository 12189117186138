
@import "~lessRoot/_mixins";

.brand-banner-widget {
	display: flex;
	flex-direction: column-reverse;
	height: min-content;
	position: relative;
	text-decoration: none;
	border-radius: 8px;
	overflow: hidden;

	.tablet-and-up-styles({
		flex-direction: row-reverse;

		&--left {
			flex-direction: row;
		}

		&--half {
			.brand-banner-widget__body {
				width: 50%;
			}
		}
	});

    &__body {
		height: fit-content;
		min-height: 125px;
		width: 100%;
		padding: 20px 15px;

		.tablet-and-up-styles({
			width: 280px;
			padding: 24px;
		});

		.desktop-min-only-styles({
			width: 500px;
			padding: 40px;
		});
    }

	&__logo-picture {
		display: flex;
		position: relative;
		z-index: 1;
		max-height: 40px;
		max-width: 100%;
		margin-bottom: 20px;

		&__img {
			width: 100%;
			object-fit: contain;
			object-position: bottom left;
		}

		.tablet-and-up-styles({
			margin-bottom: 24px;
			max-height: 80px;
		})
	}

	&__heading {
		font-size: 1.375rem;
		line-height: 1.17;
		font-weight: bold;
		margin-bottom: 0;

		.desktop-min-only-styles({
			font-size: 1.75rem;
		});
	}

	&__subheading {
		font-size: 1.125rem;
		line-height: 1.17;
		margin-top: 4px;
		margin-bottom: 0;

		.desktop-min-only-styles({
			font-size: 1.375rem;
			margin-top: 12px;
		});
	}

	&__button {
		margin-top: 20px;
		z-index: 3;
		position: relative;
		text-transform: uppercase;

		.desktop-min-only-styles({
			margin-top: 24px;
		});
	}

	&__image {
		height: 160px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.tablet-and-up-styles({
			height: auto;
			flex: 1 1;
			position: relative;
			z-index: -1;

			img {
				position: absolute;
				inset: 0;
			}
		});
    }

	&__link-mask {
		position: absolute;
		inset: 0;
		z-index: 2;
	}
}
