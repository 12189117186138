
.post-skeleton {
	opacity: 0.8;
	animation: postSkeletonOpacityPulse 2s infinite ease-in-out;
	width: 100%;

	.cls-1 {
		fill: #fff;
		stroke: #cbd0e4;
	}

	.cls-2 {
		fill: #cbd0e4;
		stroke-width :0px;
	}

}

@keyframes postSkeletonOpacityPulse {
	0%, 100% {
		opacity: 0.8;
	}
	50% {
		opacity: 0.5;
	}
}
