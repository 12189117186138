
@import "~lessRoot/_mixins";

.brand-logos-widget-stacked {
	padding: 0 15px;

	.styles-at-min(1470px, {
		padding: 0;
	});

	// ========
	// Item Gap
	// ========
	--blw-gap: .9375rem; //15px

	.tablet-and-up-styles({
		--blw-gap: 1rem;
	});


	// =========
	// Item Size
	// =========
	--blw-item-size: 90px;

	.tablet-and-up-styles({
		--blw-item-size: 150px;
	});

	.desktop-min-only-styles({
		--blw-item-size: 200px;
	});


	// =======
	// Columns
	// =======
	--blw-columns: var(--blw-columns--mobile);

	.mobile-and-up-styles({
		--blw-columns: var(--blw-columns--mobile-large);
	});

	.tablet-and-up-styles({
		--blw-columns: var(--blw-columns--tablet);
	});

	.desktop-min-only-styles({
		--blw-columns: var(--blw-columns--desktop);
	});


	// =============================
	// Grid Styling and Calculations
	// =============================

	&__grid {
		display: grid;
		justify-content: center;
		max-width: 1280px;
		margin: 0 auto;
		gap: var(--blw-gap);
		grid-template-columns: repeat(
			var(--blw-columns),
			minmax(
				calc(var(--blw-item-size) - 50px),
				200px
			)
		);

		& > * {
			--blwi-width: 100%;
			--blw-shift-item-by-spaces: var(--blw-shift-item-by-spaces--mobile);

			.mobile-and-up-styles({
				--blw-shift-item-by-spaces: var(--blw-shift-item-by-spaces--mobile-large);
			});

			.tablet-and-up-styles({
				--blw-shift-item-by-spaces: var(--blw-shift-item-by-spaces--tablet);
			});

			.desktop-min-only-styles({
				--blw-shift-item-by-spaces: var(--blw-shift-item-by-spaces--desktop);
			});

			transform: translateX(calc(var(--blw-shift-item-by-spaces) * (100% + var(--blw-gap))));
		}
	}
}
