
@import "~lessRoot/_mixins";

.page-seo-text-widget {
	.about-page-title {
		color: #191B26;
		font-size: 1.375rem;
		font-weight: 700;
		line-height: 1.625rem;
		margin-bottom: 12px;
		padding-top: 15px;
		border-top: 2px solid #E5E7F2;
	}

	.view-more-link {
		display: inline-block;
		font-size: 0.875rem;
		font-weight: 500;
		color: @BB;

		:deep(.icon) {
			font-size: 0.625rem;
		}
	}

	:deep(.dxp-page-seo-text) {
		color: #4C5271;
		font-size: 0.75rem;

		h3, h4 {
			font-weight: 700;
			margin: 12px 0;
		}

		h3 {
			font-size: 1rem;
		}

		h4 {
			font-size: 0.875rem;
		}

		p, div {
			margin-bottom: 12px;
			font-size: 0.75rem;
		}

		ol, ul {
			font-size: 0.75rem;
			margin-bottom: 12px;
			padding-left: 1rem;
			line-height: 1.2;
		}

		// quill
		ol li[data-list="bullet"] {
			list-style: disc;
		}

		ol li[data-list="ordered"] {
			list-style: decimal;
		}

		em {
			font-style: italic;
		}
	}
}
