
.product-price-display {
	line-height: 1;
	display: flex;
	line-height: 1;
	align-items: flex-start;
	.dollar-sign {
		padding: 1px 0 0 0px;
		font-size: 60%;
		line-height: 1;
	}
	.price-cents {
		font-size: 60%;
		padding: 2px 0 0 1px;
		line-height: 1;
	}
}
