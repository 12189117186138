
    @import "~lessRoot/_mixins";

.tg-title-banner {
	margin-bottom: 15px;
	display: block;
	width: 100%;
	.desktop-min-only-styles({
		margin-bottom: 24px;
	});

	&__picture {
		display: block;
		line-height: 0%;
	}

	&__image {
		width: 100%;
		height: auto;
		border-radius: 8px;
	}

}
