
@import "~lessRoot/_mixins";

.dxp-animal-rx {
	position: relative;
	display: grid;
	overflow: hidden;
	border: 1px solid #E5E7F0;
	border-radius: 8px;
	padding-left: 20px;
	grid-template-columns: 1fr min-content;
	grid-template-rows: 15px auto min-content auto 20px;
	grid-template-areas:
		".       ."
		"content content"
		"link    image"
		"logo    image"
		".       image";

	.tablet-and-up-styles({
		padding-left: 23px;
		gap: 0 16px;
		grid-template-columns: 1fr min-content min-content;
		align-items: center;
		grid-template-rows: 13px auto auto 13px;
		grid-template-areas:
			".       .    image"
			"content logo image"
			"link    logo image"
			".       .    image";
	});

	.desktop-min-only-styles({
		padding-left: 45px;
		gap: 0 16px;
		grid-template-rows: 0 auto auto 0;
	});

	.styles-at-min(1220px, {
		gap: 0;
	});


	&__content {
		grid-area: content;
		align-self: end;
		padding-right: 20px;

		.tablet-and-up-styles({
			padding-right: 0;
		});

	}

	&__title {
		margin-bottom: 2px;
		font-size: 20px;
		line-height: 24px;
		font-weight: 500;

		.tablet-and-up-styles({
			margin-bottom: 0;
		});

		.desktop-min-only-styles({
			margin-bottom: 4px;
			font-size: 22px;
			line-height: 30px;
		});

		.styles-at-min(1220px, {
			margin-bottom: 6px;
			font-size: 30px;
			line-height: 1.2;
		});
	}

	&__description {
		font-size: 14px;
		line-height: 18px;
		margin-bottom: 10px;

		.tablet-and-up-styles({
			font-size: 14px;
			line-height: 28px;
			margin-bottom: 2px;
		});

		.desktop-min-only-styles({
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 10px;
		});

		.styles-at-min(1220px, {
			font-size: 18px;
			line-height: 28px;
		});
	}

	&__logo {
		height: 75px;
		grid-area: logo;
		align-self: end;
		margin-left: 3px;

		.tablet-and-up-styles({
			height: 80px;
			align-self: center;
			margin-left: 0;
		});

		.desktop-min-only-styles({
			height: 150px;
		});
	}

	&__link {
		grid-area: link;
		align-self: start;
	}

	&__learn {
		display: inline-block;
		width: auto;
		text-transform: uppercase;
		z-index: 3;
		position: relative;

		.tablet-styles({
			font-size: 14px;
			padding: 9px 15px;
		});
	}

	&__image {
		display: flex;
		align-items: center;
		grid-area: image;

		.tablet-and-up-styles({
			align-self: end;
		});

		.styles-at-min(1220px, {
			margin-left: 35px;
		});

		&--large {
			display: none;
			height: 120px;
			padding-top: 8px;

			.tablet-and-up-styles({
				display: block;
			});

			.desktop-min-only-styles({
				height: 194px;
			});
		}

		&--small {
			height: 164px;

			.tablet-and-up-styles({
				display: none;
			});
		}
	}

	&__link-mask {
		position: absolute;
		inset: 0;
		z-index: 2;
	}
}
